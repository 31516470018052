/* Main battery container */
.battery {
    width: 150px;
    height: 80px;
    border: 4px solid #ffffff;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  /* Battery level (charging) */
  .battery-level {
    width: 20%;
    height: 100%;
    background-color: #ff5e00;
    animation: charge 0.85s linear forwards; /* Animation lasts 1100ms */
    border-radius: 6px 0 0 6px;
    align-items: center;
  }
  
  /* Animation for battery charging */
  @keyframes charge {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  /* Battery tip (for aesthetics) */
  .battery::after {
    content: '';
    width: 10px;
    height: 40px;
    background-color: white;
    position: absolute;
    right: -14px;
    top: 16px;
    border-radius: 2px;
  }
  