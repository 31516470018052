.carousel {
    background-color: white;
    /* margin-top: 3rem; */
    max-width: 1280px;
    margin: 0 auto;
    /* height: 100vh; */
}

/* Dots Styling */
.custom-dot {
    background-color: lightgray;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 20px 10px;
    display: inline-block;
    cursor: pointer;
}

.custom-dot.active-dot {
    background-color: #ff5e00; /* Orange color for active dot */
}


.carousel-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%; /* Make each slide fill the available height */
    /* padding: 1rem; */
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* padding: 20px; */
    height: 100%;
    width: 100%;
}

.carousel-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    max-height: 65%;
    /* margin-top: 40px; */
    /* margin-bottom: 40px; */
}

@media (max-height: 750px) and (min-width: 640px) {
    .carousel-image-container {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.carousel-image {
    max-width: 100%;
    object-fit: contain;
    cursor: pointer;
    /* height: 100%; Ensures the image retains its aspect ratio */
}

/* @media (min-width: 640px) {
    .carousel-image {
        height: 70%;
    }
} */

.carousel-slide {
    transition: transform 0.3s ease;
}

.carousel .react-multi-carousel-item--active {
    transform: scale(1); /* Active item (center) at full size */
}

.carousel .react-multi-carousel-item {
    transform: scale(0.8); /* Smaller size for other items */
}

/* Initial state of the carousel arrows */
.react-multiple-carousel__arrow {
    background-color: white; /* Initial white background */
    border-radius: 50%; /* Make the arrows circular */
    border: 2px solid #ff5e00; /* Orange border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

/* Arrow icon style */
.react-multiple-carousel__arrow::before {
    color: #ff5e00; /* Orange arrow color */
    font-size: 20px; /* Adjust the size of the arrow */
    font-weight: 700;
}

/* Hover state */
.react-multiple-carousel__arrow:hover {
    background-color: #ff5e00; /* Orange background on hover */
    border-color: #ff5e00; /* Keep the border orange */
}

/* Change arrow color to white on hover */
.react-multiple-carousel__arrow:hover::before {
    color: white; /* White arrow color on hover */
}

.coming-soon-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-20deg);
    /* background-color: rgba(255, 0, 0, 0.7); */
    border: 10px solid rgba(255, 0, 0, 0.7);
    color: rgba(255, 0, 0, 0.7);
    font-size: 2rem;
    font-weight: bold;
    width: 600px;
    z-index: 1;
    display: flex;
    text-align: center;
    pointer-events: none;
    justify-content: center;
}

.coming-soon-text {
    display: flex;
    font-size: 5.5rem;
    text-align: center;
}

.coming-soon-overlay.more-red {
    background-color: rgba(255, 0, 0, 0.9); /* Darker red on hover */
}

@media(max-width: 760px) {
    .coming-soon-overlay {
        top: 60%;
        width: 300px;
    }

    .coming-soon-text {
        font-size: 2.5rem;
    }
}

.image-container {
    position: relative;
}

.view-more {    
    font-size: 1.5rem;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    border-radius: 10px;
    text-align: center;
    /* z-index: 999; */
}

.view-more-light {
    /* width: 100%; */
    /* box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25); */
    /* border: 1px solid black; */
    background: #ff5e00;
    color: black;
    /* background: radial-gradient(circle, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8)); */
}

.view-more-dark {
    /* width: 300px; */
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    /* border: 1px solid #ff5e00; */
    color: #b78670;
    background: #141414e6
}

.view-more-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle, rgba(20, 20, 20, 0.9), rgba(0, 0, 0, 0.8));
    border: 2px solid #ff5e00;
    color: #ff5e00;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 20px;
    width: 250px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Adds a shadow for depth */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}



.carousel-image:hover + .view-more-overlay {
    opacity: 1;
    visibility: visible;
}

.view-more-overlay:hover {
    opacity: 1;
    visibility: visible;
}

.view-more-overlay:hover + .hover-darken{
    opacity: 0.8; /* Slightly reduces opacity */
    filter: brightness(50%); /* Darkens the image */
}

.view-more-overlay::before {
    content: "";
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border: 2px solid rgba(255, 94, 0, 0.6); /* Glowing orange border */
    border-radius: 22px;
    box-shadow: 0 0 10px 4px rgba(255, 94, 0, 0.3); /* Soft glow effect */
    animation: pulse 2s infinite; /* Creates a pulsing effect */
    z-index: -1;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 10px 4px rgba(255, 94, 0, 0.3);
    }
    50% {
        box-shadow: 0 0 20px 8px rgba(255, 94, 0, 0.5);
    }
    100% {
        box-shadow: 0 0 10px 4px rgba(255, 94, 0, 0.3);
    }
}

.eye-icon {
    margin-right: 8px;
}

.hover-darken:hover {
    opacity: 0.8; /* Slightly reduces opacity */
    filter: brightness(50%); /* Darkens the image */
}

.diagonal-ribbon-top-left,
.diagonal-ribbon-bottom-right {
    position: absolute;
    background-color: #ff5e00;
    color: #fff6ef;
    font-weight: bold;
    padding: 0px 50px; /* Adjusts the width of the ribbon */
    font-size: 0,7rem;
    white-space: nowrap;
}

.diagonal-ribbon-top-left {
    top: 15px; /* Positions ribbon partially outside */
    left: -50px;
    transform: rotate(-45deg);
}

.diagonal-ribbon-bottom-right {
    bottom: 15px;
    right: -50px;
    transform: rotate(-45deg);
}
