.carousel-slide {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.overlay-text {
    position: absolute;
    left: 2rem;
    color: white;
    max-width: 41rem;
}

.overlay-text-top {
    position: absolute;
    top: 1rem;
    width: 100%;
}

.overlay-text-bottom {
    position: absolute;
    bottom: 4rem;
    width: 100%;
}

/* Adjust the position of dots */
.custom-dot-list {
    position: absolute;
    bottom: 5px; /* Adjust upwards from the bottom */
    width: 100%;
    text-align: center;
}
