.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------------ CUSTOM CSS -------------- */

.container {
  /* max-width: 1024px; */
  margin: 0 auto;
}

.color-animation {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

}

/* md to lg */
@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    max-width: 672px;
  }
}

/* max md */
@media (max-width: 768px) {
  .container {
    max-width: 640px;
  }
}

.transition-up {
  transition: all 750ms ease-in-out;
  transform: translateY(100%);
  opacity: 0;
}

.transition-up.show {
  transform: translateY(0);
  opacity: 1;
}

.transition-up.nav {
  transition-duration: 500ms;
}

.background-nav {
  background: rgba(0, 0, 0, 0.69);
  backdrop-filter: blur(2px);
}

/* Smooth expand/collapse */
.transition-height {
  transition: height 0.3s ease;
}

/* Rotating the icon */
.rotate-90 {
  transform: rotate(90deg);
}
